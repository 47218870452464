import { Field, RuleType } from 'react-querybuilder';
import { userService } from './Service';
import { defaultOperators } from 'react-querybuilder';
export const validator: RuleValidator = (q): ValidationResult => ({
    valid: true,
    reasons: ['this field is always invalid'],
}); 

const dateOperators = [{ "name": "=", "value": "=", "label": "=" }, { "name": "!=", "value": "!=", "label": "!=" }, { "name": "<", "value": "<", "label": "<" }, { "name": ">", "value": ">", "label": ">" }, { "name": "<=", "value": "<=", "label": "<=" }, { "name": ">=", "value": ">=", "label": ">=" }, { "name": "null", "value": "null", "label": "is null" }, { "name": "notNull", "value": "notNull", "label": "is not null" },  { "name": "between", "value": "between", "label": "between" }, { "name": "notBetween", "value": "notBetween", "label": "not between" }]
const numberOperators = defaultOperators.filter(w => { return "=,!=,<,>,<=,>=,null,notNull,between,notBetween".split(',').indexOf(w.value) > -1 })
const stringOperators = defaultOperators.filter(w => { return "=,null,notNull,contains,beginsWith,endsWith,doesNotContain,doesNotBeginWith,doesNotEndWith,in,notIn".split(',').indexOf(w.value) > -1 })
const selectOperators = defaultOperators.filter(op => op.name === 'in' || op.name === 'notIn')
const fields: Field[] =
    [
        {
            id: 1,
            name: 'encounternumber',
            label: 'EncounterID',
            placeholder: 'Enter EncounterID',
            validator: ({ value }) => value != "",
            operators: stringOperators,
        },
        {
            name: 'CPT.CPTCode',
            label: 'CPT',
            placeholder: 'Enter CPT',
            defaultOperator: 'beginsWith',
            operators: stringOperators,
            validator: ({ value }) => value != ""
        },
        {
            name: 'CPT.DOS', label: 'DOS', inputType: 'date'
            , validator: ({ value }) => value != "", operators: dateOperators
        },
        { name: 'CPT.DOE', label: 'DOE', inputType: 'date', operators: dateOperators },
        { name: 'CPT.FirstBillDate', label: 'First Bill Date', inputType: 'date', validator: ({ value }) => value != "", operators: dateOperators },
        { name: 'CPT.LastBillDate', label: 'Last Bill Date', inputType: 'date', validator: ({ value }) => value != "", operators: dateOperators },
        {
            name: 'CurrentAssigneeName',
            label: 'Current Owner',
            valueEditorType: 'multiselect',
            values: [],
            operators: selectOperators,
            validator: ({ value }) => value != ""
        },
        {
            name: 'CurrentAction',
            label: 'Current Action',
            valueEditorType: 'multiselect',
            values:[],
            operators: selectOperators
            , validator: ({ value }) => value != ""
        },

        { name: 'DischargeDate', label: 'DischargeDate', inputType: 'date', validator: ({ value }) => value != "", operators: dateOperators },
        { name: 'FirstDOE', label: 'First DOE', inputType: 'date', validator: ({ value }) => value != "", operators: dateOperators },
        { name: 'FirstDOS', label: 'First DOS', inputType: 'date', validator: ({ value }) => value != "", operators: dateOperators },
        { name: 'LastDOE', label: 'Last DOE', inputType: 'date', validator: ({ value }) => value != "", operators: dateOperators },
        { name: 'LastDOS', label: 'Last DOS', inputType: 'date', validator: ({ value }) => value != "", operators: dateOperators },
        //{
        //    name: 'PM_SystemDepartmentname',
        //    label: 'Department',
        //    valueEditorType: 'select',
        //    values: NewData.Department,
        //    defaultValue: 'OLYNA',
        //    operators: defaultOperators.filter(op => op.name === '=' || op.name === 'in'),
        //},
        {
            name: 'PM_System_CurrentPayorname',
            label: 'Current Payor',
            valueEditorType: 'multiselect',
            values: [], 
            operators: selectOperators
            , validator: ({ value }) => value != "",
        },
        {
            name: 'PM_System_Currentpayorgroupname',
            label: 'Current Payor Group',
            valueEditorType: 'multiselect',
            values: [],
            operators: selectOperators
            , validator: ({ value }) => value != "",
        },
        {
            name: 'PM_System_PrimaryPayorname',
            label: 'Primary Payor',
            valueEditorType: 'multiselect',
            values: [],
            operators: selectOperators
            , validator: ({ value }) => value != "",
        },
        {
            name: 'PM_System_Primarypayorgroupname',
            label: 'Primary Payor Group',
            valueEditorType: 'multiselect',
            values: [],
            operators: selectOperators
            , validator: ({ value }) => value != "",
        },
        {
            name: 'PM_System_Servicelocationname',
            label: 'Service Location',
            valueEditorType: 'multiselect',
            values: [],
            operators: selectOperators
            , validator: ({ value }) => value != "",
        },
        {
            name: 'CurrentQueueName',
            label: 'Current Queue',
            valueEditorType: 'multiselect',
            values: [],
            operators: selectOperators
            , validator: ({ value }) => value != "",

        },
        //{
        //    name: 'PM_System_facilityname',
        //    label: 'Facility Name',
        //    valueEditorType: 'multiselect',
        //    values: await getServicingProviders(),
        //    defaultValue: 'Prepare Center Imaging',
        //    operators: defaultOperators.filter(op => op.name === '='),
        //},
        {
            name: 'patient_accountnumber',
            label: 'Patient Account Number',
            placeholder: 'Enter Account Number',
            defaultOperator: 'beginsWith',
            operators: stringOperators,
             validator: ({ value }) => value != "",

        },
        {
            name: 'PatientName',
            label: 'Patient Name ',
            placeholder: 'Enter Patient Name',
            defaultOperator: 'beginsWith',
            operators: stringOperators
            , validator: ({ value }) => value != "",

        },
        {
            name: 'UniquePracticeID',
            label: 'Practice Name',
            valueEditorType: 'multiselect',
            values: [],
            operators: selectOperators
            , validator: ({ value }) => value != "",
            //"valueSources": ["value","name"]
            //"value": "value"
        },
        {
            name: 'Total_Balance',
            label: 'Total Balance',
            inputType: 'number',
            validator: ({ value }) => /^\d{1,5}|\d{0,5}\.\d{1,2}$/.test(value)
            , operators: numberOperators
        },
        {
            name: 'Total_Insurance_balance',
            label: 'Total Insurance Balance',
            inputType: 'number',
            validator: ({ value }) => /^\d{1,5}|\d{0,5}\.\d{1,2}$/.test(value)
            , operators: numberOperators
        },
        {
            name: 'Total_Patient_Balance',
            label: 'Total Patient Balance',
            inputType: 'number',
            validator: ({ value }) => /^\d{1,5}|\d{0,5}\.\d{1,2}$/.test(value)
            , operators: numberOperators
        },
        {
            name: 'Total_charges',
            label: 'Total Charges',
            inputType: 'number',
            validator: ({ value }) => /^\d{1,5}|\d{0,5}\.\d{1,2}$/.test(value)
            , operators: numberOperators
        },
        {
            name: 'total_adjustment',
            label: 'Total Adjustments',
            inputType: 'number',
            validator: ({ value }) => /^\d{1,5}|\d{0,5}\.\d{1,2}$/.test(value)
            , operators: numberOperators

        },
        {
            name: 'total_insurance_adjustment',
            label: 'Total Insurance Adjustment',
            inputType: 'number',
            validator: ({ value }) => /^\d{1,5}|\d{0,5}\.\d{1,2}$/.test(value)
            , operators: numberOperators

        },
        {
            name: 'total_insurance_payment',
            label: 'Total Insurance Payment',
            inputType: 'number',
            validator: ({ value }) => /^\d{1,5}|\d{0,5}\.\d{1,2}$/.test(value)
            , operators: numberOperators

        },
        {
            name: 'total_patient_payment',
            label: 'Total Patient Payment',
            inputType: 'number',
            validator: ({ value }) => /^\d{1,5}|\d{0,5}\.\d{1,2}$/.test(value)
            , operators: numberOperators

        },
        {
            name: 'total_patients_adjustment',
            label: 'Total Patient Adjustment',
            inputType: 'number',
            validator: ({ value }) => /^\d{1,5}|\d{0,5}\.\d{1,2}$/.test(value)
            , operators: numberOperators
        },
        {
            name: 'total_payment',
            label: 'Total Payment',
            inputType: 'number',
            validator: ({ value }) => /^\d{1,5}|\d{0,5}\.\d{1,2}$/.test(value)
            , operators: numberOperators
        }
    ].sort((a, b) => {
        return (a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1) * 1;
    })

class FieldsService {
    async GetFilds() {
        return fields;
    }
}
export const fieldsService: FieldsService = new FieldsService();

