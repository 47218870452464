import { useState, useEffect, useRef } from 'react';
import React from 'react';
import { QueryBuilder, convertToIC } from 'react-querybuilder';
import { QueryBuilderAntD } from '@react-querybuilder/antd';
import { QueryBuilderDnD } from '@react-querybuilder/dnd';
import * as ReactDnD from 'react-dnd';
import * as ReactDndHtml5Backend from 'react-dnd-html5-backend';

import 'react-querybuilder/dist/query-builder.css';
import { formatQuery } from 'react-querybuilder/formatQuery';
import { parseMongoDB } from 'react-querybuilder/parseMongoDB';

import { fieldsService } from './components/fields';
import { selectStyles } from './components/selectOptionStyle';

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import 'bootstrap/dist/css/bootstrap.min.css';

import Swal from 'sweetalert2'
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import settings from './settings.png'
import logo from './assets/images/logo-white.svg'
import darkSettings from './dark.png'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Avatar from '@mui/joy/Avatar';
import "./App.css"
import DarkMode from './components/DarkMode';
// import { useNavigate } from "react-router-dom";
import { Dropdown, MenuItem, DropdownButton } from "react-bootstrap";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { useHistory } from 'react-router-dom';
import { colorCode } from './avtar'
import { dropDown, options } from './dropDownData'
import { responsibledropDown } from './responsibleDropDown'
import { Redirect } from 'react-router';
import { userService } from '../src/components/Service';
import { defaultValidator } from 'react-querybuilder';
import { ValueEditor, ValueSelector, defaultOperators } from 'react-querybuilder';
const initialQuery: RuleGroupType = {
    combinator: 'and',
    rules: [],
};
let fields = [];
function goBack() {
    window.history.back();
}

function redirectUrl() {
    window.location.href = ApiUrl + '/login';
    //<Redirect to='https://yojaka.viriminfotech.com/' />

}
const CustomFieldSelector = async (props: FieldSelectorProps) => {
    return <ValueSelector {...props} />;
}
const MyActionElement = (props: ValueEditorProps) => {
    if (props.fieldData.valueEditorType == "autoFile") {
        return <Select
            value={props.value}
            isMulti
            onChange={v => handleOnChange(v)}
            options={selectOptions}
        />
    }
    else {
        let newprops = Object.assign({}, props);
        if (props.fieldData.valueEditorType == "multiselect") {
            newprops.values = getValues(props.fieldData.name);
        }

        return <ValueEditor {...newprops} />
    }
};
const alphabetList = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
const style = {
    position: 'absolute',
    top: '15%',
    left: '87%',
    transform: 'translate(-50%, -50%)',
    //width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    border: "white"
    //p: 4,
};

const url = window.location.href;
const ApiUrl = userService.baseUrl; 
const params = new URL(url).searchParams;
let UserName = '';
var mongoid = ''
if (url.split('/')) {
    mongoid = url.split('/').pop()

}
//else {
//    mongoid = "66c754a12c2cd3b4a891f0c3"
//}
var DataforSaving = ''
var selectedOwner = ''
var selectedResponsible = ''
var query_rules = [];
var loading = true;


function getRules(query_rule) {
    if (query_rule.rules) {
        let rules = Object.assign([], query_rule.rules);
        return {
            combinator: query_rules.combinator,
            rules: rules.map(w => {
                return getRules(w)
            })
        }
    }
    else {
        if (query_rule.field == "UniquePracticeID") {
            return {
                field: query_rule.field,
                operator: query_rule.operator,
                value: query_rule.value.split(",").map(s => { return s.split("||")[0] }).join(","),
            }
        }
        else {
            return query_rule
        }
    }
}
function saveData() {

    if (isValid()) {
        let rules = Object.assign([], query_rules.rules);
        query_rules = getRules(query_rules);
        DataforSaving = formatQuery(query_rules, 'mongodb');
        var QueueName = document.getElementById('validationCustom01');
        var Description = document.getElementById('validationCustom02');
        var Isactive = document.getElementById('validationCustom03').checked ? 1 : 0;
        var Order = document.getElementById('validationCustom04');
        var Color = document.getElementById('validationCustom05');

        var Ownervalue = ''
        var Reponsiblevalue = ''
        //handle null value for owner 
        if (selectedOwner) {
            Ownervalue = selectedOwner.map((x) => x.value);
        }
        else {
            Ownervalue = '';
        }
        //handle null value for responsible 
        if (selectedResponsible) {
            Reponsiblevalue = selectedResponsible.map((x) => x.value);
        }
        else {
            Reponsiblevalue = '';
        }
        if (QueueName != null) {
            QueueName = QueueName.value
        }
        else {
            QueueName = ''

        }
        if (Description != null) {
            Description = Description.value
        }

        if (Order != null) {
            Order = Order.value
        }
        if (Color != null) {
            Color = Color.value
        }
        var data =
        {
            "Filter": DataforSaving,
            "QueueName": QueueName,
            "Description": Description,
            "Active": Isactive,
            "Order": (parseInt(Order) == parseFloat(Order)) ? (parseFloat(Order)) + 0.0001 : parseFloat(Order),
            "Color": Color,
            "Owner": Ownervalue.join(','),
            "Responsible": Reponsiblevalue.join(','),
            "FilterJson": "filterJsonData",
            Action: "",
            ActionJson: "",
            "id": (mongoid) ? mongoid : "66c754a12c2cd3b4a891f0c3"
        }

        userService.setworkqueue(data).then(responseData => {
            Swal.fire({
                title: 'Information!',
                text: responseData.message,
                type: "warning",
                showCancelButton: false,
                confirmButtonColor: "#7367f0",
                confirmButtonText: "OK",
                cancelButtonText: "No",
                closeOnConfirm: true,
                closeOnCancel: true
            }).then(isConfirm => {
                if (responseData.result == 1)
                    goBack()
            });
        });
    }
    else {
        //Swal.fire("Please enter values.");
    }
}

function isValid() {
    var result = true;
    if (query_rules.rules.length == 0) {
        result = false;

        document.getElementsByClassName('queryBuilder')[0].classList.add("queryBuilder-invalid")
        document.getElementsByClassName('queryBuilder')[0].title = 'Please add atleast one rule.'
    }
    else {
        document.getElementsByClassName('queryBuilder')[0].classList.remove("queryBuilder-invalid")
        document.getElementsByClassName('queryBuilder')[0].title = 'Please add atleast one rule.'
    }
    if ((document.getElementsByClassName('queryBuilder-invalid')?.length ?? 0) > 0) {
        result = false;
    }
    if (document.getElementById('validationCustom01').value == "") {
        document.getElementById('validationCustom01').style.borderColor = 'red'
        document.getElementById('validationCustom01').title = 'Please enter queue name.'
        result = false;
    }
    else {
        document.getElementById('validationCustom01').style.borderColor = ''
        document.getElementById('validationCustom01').title = ''
    }
    if (document.getElementById('validationCustom04').value == "") {
        document.getElementById('validationCustom04').style.borderColor = 'red'
        document.getElementById('validationCustom04').title = 'Please enter order.'
        result = false;
    }
    else {
        document.getElementById('validationCustom04').style.borderColor = ''
        document.getElementById('validationCustom04').title = ''
    }
    return result;
}
var OptionGroup = [];
var isOptionGroup = false;
let selectOptions = [];
function handleOnChange(v) {
    debugger;
}
async function loadValues(type: string) {
    if (!isOptionGroup) {
        console.log(defaultOperators)
        // return [];
        isOptionGroup = true
        await userService.validate();
        let data = await Promise.all([
            userService.getUsers(),
            userService.getActions([]),
            userService.getServiceLocations([{ clientid: '-1', uniqueservicelocationid: -1, pagesize: 10000, pagestartrecord: 0 }]),
            userService.getClientLookup(Request),
            userService.getQueueLookup([{ clientid: '-1' }])
        ]);
        OptionGroup = data;
        Promise.all([
            userService.GetPrimaryPayors([{ clientid: '-1', uniqueprimarypayorid: -1, pagesize: 10000, pagestartrecord: 0 }]),
            userService.GetPrimaryPayorGroups([{ clientid: '-1', uniqueprimarypayorgroupid: -1, pagesize: 10000, pagestartrecord: 0 }])
        ]).then(locupData => {
            OptionGroup.push(locupData[0]);
            OptionGroup.push(locupData[1]);
            selectOptions = OptionGroup[5].filter((s: any) => { return s.primarypayorname })?.map(s => ({ name: s.primarypayorname, label: s.primarypayorname }))
        });
        return data;
    }

}
function getValues(type: string) {
    let i = 100;


    switch (type) {
        case 'CurrentAssigneeName':
            {
                return OptionGroup[0].map(s => ({ name: s.username, label: s.username }));
            }
        case 'CurrentAction':
            {
                return OptionGroup[1].map(s => ({ name: s.actionname, label: s.actionname }));
            }
        case 'PM_System_CurrentPayorname':
        case 'PM_System_PrimaryPayorname':
            {
                return OptionGroup[5]??[].filter((s: any) => { return s.primarypayorname })?.map(s => ({ name: s.primarypayorname, label: s.primarypayorname }));
            }
        case 'PM_System_Servicelocationname':
            {
                return OptionGroup[2].filter((w, indx) => { return OptionGroup[3].findIndex(s => { return s.servicelocationname && s.uniqueservicelocationid == w.uniqueservicelocationid }) == indx }).map(s => ({ name: s.servicelocationname, label: s.servicelocationname }));
            }
        case 'UniquePracticeID':
            {
                return OptionGroup[3].map(s => ({ value: s.clientid, name: s.clientid + "||" + s.clientname, label: s.clientname }));
            }
        case 'PM_System_Currentpayorgroupname':
        case 'PM_System_Primarypayorgroupname':
            {
                return OptionGroup[6]??[].filter((s: any) => { return s.primarypayorgroupname }).map(s => ({ name: s.primarypayorgroupname, label: s.primarypayorgroupname }));
            }
        case 'CurrentQueueName':
            {
                return OptionGroup[4].filter((s: any) => { return s._id && s.id != mongoid }).map(s => ({ name: s.queueName, label: s.queueName }));
            }

    }

}

let ownerList = [];

function App() {
    const [query, setQuery] = useState(initialQuery);
    const [fieldData, setfields] = useState(fields);
    const [queryIC, setQueryIC] = useState(initialQuery);
    let [selectedOptions, setSelectedOptions] = React.useState([]);
    const [selectOptionsResposible, OptionsResponsible] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [selectIsLoading, setSelectIsLoading] = useState(true);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [required, setRequired] = useState(false)
    const [UserName, setUserName] = useState('G');
    //const [loading, setLoading] = useState(true);
    const [optionCopy, setOption] = useState(false)
    // const refQueryBuilder = React.useRef();
    const refResponsibSelect = useRef();
    const refOwnerSelect = useRef();

    const handleSelect = (selectedOptions) => {
        setSelectedOptions(selectedOptions);
        selectedOwner = selectedOptions
    };

    const handleSelectResponsible = (OptionsResponsible) => {
        setSelectedOptions(OptionsResponsible);
        selectedResponsible = OptionsResponsible
    };

    let islookup = false;
    useEffect(() => {
        if (fieldData.length == 0) {
            fieldsService.GetFilds().then(data => { fields = data; });
        }
    }, [UserName]);
    let isvalidTriggered = false;
    useEffect(() => {
        if (!isvalidTriggered) {
            validate();
            isvalidTriggered = true;
        }

        const interval = setInterval(() => {
            validate();
        }, 30000);
        return () => clearInterval(interval);
    }, []);
    useEffect(() => {
        refOwnerSelect.current.state.selectValue = dropDown;
        selectedOwner = dropDown
        //selectedOwner
    }, [JSON.stringify(dropDown)]);

    useEffect(() => {
        if (!isOptionGroup) {
            loadValues('owner').then(w => {
                if (w)
                    if (!islookup)
                        lookup();
            })
        }
    }, [JSON.stringify(isvalidTriggered)]);


    useEffect(() => {
        refResponsibSelect.current.state.selectValue = responsibledropDown;
        selectedResponsible = responsibledropDown
    }, [JSON.stringify(responsibledropDown)])

    function getWorkqueue() {

        userService.getWorkqueue(mongoid).then(res => {
            //setLoading(false);
            loading = false;
            var responseData = JSON.parse(res);
            var queryData = responseData[0]
            document.getElementById('validationCustom01').value = queryData.QueueName;
            document.getElementById('validationCustom02').value = queryData.Description;
            document.getElementById('validationCustom03').checked = queryData.Active == 1;
            document.getElementById('validationCustom04').value = parseFloat(queryData.Order).toFixed(2)
            document.getElementById('validationCustom05').value = queryData.Color
            //owner selected                
            if (queryData.Owner) {
                let newdata = options.filter(w => { return queryData.Owner.includes(w.value) })
                for (let i = 0; i < newdata.length; i++) {
                    if (dropDown.findIndex(w => { return newdata[i].value == w.value }) < 0) {
                        dropDown.push(newdata[i]);
                    }
                }
            }

            if (queryData.Responsible) {
                let newdata = options.filter(w => { return queryData.Responsible.includes(w.value) })
                for (let i = 0; i < newdata.length; i++) {
                    if (responsibledropDown.findIndex(w => { return newdata[i].value == w.value }) < 0) {
                        responsibledropDown.push(newdata[i]);
                    }
                }

            }
            const q = parseMongoDB(queryData.Filter);

            q.rules = q.rules.map(w => {
                if (w.field == "UniquePracticeID") {
                    w.value = OptionGroup[3].filter(s => {
                        return w.value.split(",").indexOf(s.clientid) >= 0
                    }).map(s => { return s.clientid + "||" + s.clientname }).join(",");
                }
                return w
            });
            console.log(q);
            const qIC = convertToIC(q);
            setSelectIsLoading(false);
            setQuery(q)
            setQueryIC(qIC)
        });
    }

    function validate() {
        userService.validate([])
            .then(response => {
                // console.log(response.status)
                if (response.status == 401) {
                    redirectUrl()
                }
                response.json().then(data => {
                    // console.log(data, 'djdjjdjdj');
                    setUserName(data.username[0].toUpperCase());
                    //UserName = data.username
                })
            })
            .catch(error => console.error(error));
    }
    function lookup() {
        islookup = true;
        //userService.getUsers().then(data =>
        let data = OptionGroup[0];
        {
            if (mongoid) {
                getWorkqueue();
            }
            else {
                setSelectIsLoading(false);
                //setLoading(false);
                loading = false;
                document.getElementById('validationCustom03').checked = true;
            }
            for (let i = 0; i < data.length; i++) {
                if (options.findIndex(w => { return data[i].name == w.value }) < 0) {
                    options.push({ value: data[i].username, label: data[i].name });
                }
            }
            let newoption = {
                name: 'CurrentAssigneeNamenedefaultOperatorsw',
                label: 'Current Owner new',
                valueEditorType: 'multiselect',
                values: options,
                operators: defaultOperators.filter(op => op.name === '='),
                validator: ({ value }) => value != ""
            };
            fields.push(newoption);

        }//)
    }

    //DataforSaving = formatQuery(query, 'mongodb');
    query_rules = query;
    // let menuList =[]  
    return (
        <Card>
            <Card.Header>
                <img src={logo} width="180" alt="logo" />

                <Avatar style={{ float: "right", color: 'white', background: colorCode[UserName.charCodeAt(0) - 65] }}>{UserName}</Avatar>

                <Button onClick={handleOpen} style={{ float: "right", background: "#F8F8F8", border: '#F8F8F8' }}><img src={settings} className="float-end" width="30" alt="logo" /></Button>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description">
                    <Box sx={style} className="theme-toggler">
                        <div>
                            <Button className="mat-focus-indicator btn mat-flat-button mat-button-base" style={{ background: "transparent", border: 'gray' }}><img src={settings} className="float-end" width="30" alt="logo" /></Button>
                            <span>Light</span>
                        </div>
                        <div>
                            <Button className="mat-focus-indicator btn mat-flat-button mat-button-base" style={{ background: "transparent", border: 'gray' }}><img src={darkSettings} className="float-end" width="30" alt="logo" /></Button>
                            <span>Dark</span>
                        </div>
                    </Box>
                </Modal>
            </Card.Header>
            <Card.Header>
                <Button className="mat-focus-indicator btn mat-flat-button mat-button-base" onClick={goBack}>Back</Button>

            </Card.Header>

            <DarkMode />
            <div className="container">
                <Card.Body>
                    <div className="loadingmodal" style={{ height: "100%", width: "100%", display: (loading) ? 'block' : 'none' }} >
                        <div className="preloader">
                            <div className="spinner">
                                <div className="double-bounce1"></div>
                                <div className="double-bounce2"></div>
                            </div>
                        </div>
                    </div>
                    <div className="ruleGroup">
                        <div className="row">
                            <div className="queryBuilder-form-group-main">
                                <div className="row">
                                    <div className="queryBuilder-form-group">
                                        <label className="form-label"> Queue name </label>
                                        <input type="text" className="ant-input" id="validationCustom01" name='QueueName' required={required} />
                                    </div>

                                    <div className="queryBuilder-form-group">
                                        <label className="form-label"> Description </label>
                                        <input type="text" className="ant-input" id="validationCustom02" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="queryBuilder-form-group">
                                        <label className="form-label"> Order </label>
                                        <input type="number" className="ant-input" id="validationCustom04" />

                                    </div>
                                    <div className="queryBuilder-form-group inner" >
                                        <div className="queryBuilder-form-group">
                                            <label className="form-label"> Active </label>
                                            <input type="checkbox" data-toggle="toggle" id="validationCustom03" />
                                        </div>

                                        <div className="queryBuilder-form-group">
                                            <label className="form-label"> Color </label>
                                            <input type="color" className="ant-input-color" id="validationCustom05" />

                                        </div>
                                    </div>

                                </div>
                                <div className="row">
                                    <div className="queryBuilder-form-group">
                                        <label className="form-label"> Owner </label>

                                        <Select id="validationDefault06"
                                            isMulti
                                            ref={refOwnerSelect}
                                            defaultValue={dropDown}
                                            onChange={handleSelect}
                                            options={options}
                                            styles={selectStyles}
                                            placeholder={"Owner"}
                                            isClearable
                                        />
                                    </div>
                                    <div className="queryBuilder-form-group">
                                        <label className="form-label"> Responsible </label>
                                        <Select id="validationDefault06"

                                            isMulti
                                            onChange={handleSelectResponsible}
                                            options={options}
                                            styles={selectStyles}
                                            placeholder={"Responsible"}
                                            defaultValue={responsibledropDown}
                                            isLoading={selectIsLoading}
                                            ref={refResponsibSelect}
                                        />
                                    </div>



                                </div>
                            </div>
                            <div className="queryBuilder-buttons-group-main">
                                <div className="float-end" style={{ width: "100%" }}>
                                    <Button className="mat-focus-indicator btn mat-flat-button mat-button-base" onClick={saveData}>Save</Button>
                                </div>
                                <div className="float-end" style={{ width: "100%" }}>
                                    <Button variant="contained" className="mat-focus-indicator-cencel btn mat-flat-button mat-button-base" onClick={goBack}>Cancel</Button>
                                </div>

                            </div>

                        </div>
                        <div className="row">
                            <QueryBuilderDnD dnd={{ ...ReactDnD, ...ReactDndHtml5Backend }}>

                                <QueryBuilderAntD>

                                    <QueryBuilder fields={fields} query={query}
                                        onQueryChange={setQuery}
                                        owner={options}
                                        addRuleToNewGroups
                                        showCloneButtons
                                        showCombinatorsBetweenRules
                                        controlClassnames={{ queryBuilder: 'queryBuilder-branches' }}
                                        controlElements={{
                                            valueEditor: MyActionElement,
                                            //fieldSelector: CustomFieldSelector
                                        }}
                                        //context={{ fieldData }}
                                        validator={defaultValidator}
                                        id="queryBuilder-1"
                                    />
                                </QueryBuilderAntD>
                            </QueryBuilderDnD>
                        </div>
                        <div>

                        </div>
                    </div>

                </Card.Body>
            </div>
        </Card >);
};


export default App;