export const colorCode = [
    "#9F2B68",
    "#BF40BF",
    "#800020",
    "#702963",
    "#AA336A",
    "#301934",
    "#483248",
    "#5D3FD3",
    "#E6E6FA",
    "#CBC3E3",
    "#CF9FFF",
    "#AA98A9",
    "#E0B0FF",
    "#915F6D",
    "#770737",
    "#DA70D6",
    "#C3B1E1",
    "#CCCCFF",
    "#673147",
    "#A95C68",
    "#800080",
    "#51414F",
    "#953553",
    "#D8BFD8",
    "#630330",
    "#7F00FF",
    "#722F37",
    "#BDB5D5"
  ]