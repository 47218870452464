export const selectStyles = {
    control: (base) => ({
      ...base,
      fontSize: '16px',
      fontWeight: 'bold',
      border: '1px solid #21274F !important',
      boxShadow: 'none',
      color:'white !important',
      '&:focus': {
          border: '0 !important',
      },
      
  }),
  multiValue: (base) => ({
      ...base,
      backgroundColor: '#7367f0 !important',
      color: 'white !important',
  }),
  color:'white'
  }
  