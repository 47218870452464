

class UserService {
    baseUrl = "https://ventra.kratinn.com";
    //baseUrl = "https://localhost:5162";
    ApiUrl = this.baseUrl + "/api";
    getHeaders() {
        return {
            'Access-Control-Allow-Origin': this.ApiUrl,
            'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, PATCH, OPTIONS',
            'Access-Control-Allow-Headers': 'X-Requested-With, content-type, Authorization,Cache-Control,Access-Control-Allow-Origin',
            'Content-Type': 'application/json',
            datatype: 'json',
            'Accept': 'application/json',
        }
    }
    async getUsers(type) {
        const response = await fetch(this.ApiUrl + '/user/lookup', {
            credentials: 'include',
            method: 'Get',
            headers: this.getHeaders(),
        }).catch(error => console.error(error));
        return await response.json();
    }
    async getClientLookup(request: any[]): Promise<any> {
        if (!request) {
            request = []
        }
        const response = await fetch(this.ApiUrl + '/Client/lookup', {
            credentials: 'include',
            method: 'Get',
            headers: this.getHeaders(),
            // body: request
        }).catch(error => console.error(error));
        return await response.json();
    }
    async getServiceLocations(request: any[]): Promise<any> {
        if (!request) {
            request = []
        }
        const response = await fetch(this.ApiUrl + '/Client/servicelocations', {
            credentials: 'include',
            method: 'POST',
            headers: this.getHeaders(),
            body: JSON.stringify(request)
        }).catch(error => console.error(error));
        return await response.json();
    }
    async getServicingProviders(request: any[]): Promise<any> {
        if (!request) {
            request = []
        }
        const response = await fetch(this.ApiUrl + '/Client/servicingproviders', {
            credentials: 'include',
            method: 'POST',
            headers: this.getHeaders(),
            body: JSON.stringify(request)
        }).catch(error => console.error(error));
        return await response.json();
    }
    async GetPrimaryPayorGroups(request: any[]): Promise<any> {
        if (!request) {
            request = []
        }
        const response = await fetch(this.ApiUrl + '/Client/primarypayorgroups', {
            credentials: 'include',
            method: 'POST',
            headers: this.getHeaders(),
            body: JSON.stringify(request)
        }).catch(error => console.error(error));
        return await response.json();
    }
    async GetPrimaryPayors(request: any[]): Promise<any> {
        if (!request) {
            request = []
        }
        const response = await fetch(this.ApiUrl + '/Client/primarypayors', {
            credentials: 'include',
            method: 'POST',
            headers: this.getHeaders(),
            body: JSON.stringify(request)
        }).catch(error => console.error(error));
        return await response.json();
    }
    async getActions(request: any): Promise<any> {
        if (!request) {
            request = []
        }
        const response = await fetch(this.ApiUrl + '/action/lookup', {
            credentials: 'include',
            method: 'Get',
            headers: this.getHeaders(),
            //body: request
        }).catch(error => console.error(error));
        return await response.json();
    }

    async validate(request: any): Promise<any> {
        if (!request) {
            request = []
        }
        const response = await fetch(this.ApiUrl + '/Authenticate/validate', {
            credentials: 'include',
            method: 'Post',
            headers: this.getHeaders(),
            body: JSON.stringify(request)
        }).catch(error => console.error(error));
        return response;
    }

    async setworkqueue(request: any[]): Promise<any> {
        if (!request) {
            request = []
        }
        const response = await fetch(this.ApiUrl + '/workqueue', {
            credentials: 'include',
            method: 'POST',
            headers: this.getHeaders(),
            body: JSON.stringify(request)
        }).catch(error => console.error(error));
        return await response.json();
    }
    async getWorkqueue(id: string): Promise<any> {

        const response = await fetch(this.ApiUrl + '/workqueue/' + id, {
            credentials: 'include',
            method: 'Get',
            headers: this.getHeaders(),
        }).catch(error => console.error(error));
        return await response.json();
    }

    async getQueueLookup(request: any[]): Promise<any> {

        const response = await fetch(`${this.ApiUrl}/task/queue/lookup`, {
            credentials: 'include',
            method: 'Post',
            headers: this.getHeaders(),
            body: JSON.stringify(request),
        }).catch(error => console.error(error));
        return await response.json();

    }

    async getFileds(request: any[]): Promise<any> {

        const response = await fetch(`${this.ApiUrl}/task/queue/lookup`, {
            credentials: 'include',
            method: 'Post',
            headers: this.getHeaders(),
            body: JSON.stringify(request),
        }).catch(error => console.error(error));
        return await response.json();

    }
}
export const userService: UserService = new UserService();